import { notification } from "antd";

export default {
  convertPortfolios: function (portfolios) {
    return portfolios.map((portfolio) => portfolio.id);
  },
  iOS: function () {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  },
  getIosWarning: function () {
    if (this.iOS()) {
      notification["info"]({
        message: "IOS Warning",
        description:
          "We have detected that you are using an IOS device. To download this file, please go to settings -> Safari/Chrome (depending on your browser selection) -> Set Block Pop-ups to false.",
      });
    }
  },
  getCardTitleStyle: function () {
    return {
      backgroundColor: "#00475a",
      color: "#FFF",
      wordBreak: "break-all",
    };
  },
  getSelectedCardTitleStyle: function () {
    return { backgroundColor: "#91c840", color: "#FFF" };
  },
  showLoader: function () {
    document.getElementById("loader").style.display = "block";
  },
  closeLoader: function () {
    document.getElementById("loader").style.display = "none";
  },
};
