import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../home/Home";
import ErrorBoundary from "../error/ErrorBoundary";
import Error404 from "../error/Error-404";
import ContactUs from "../contactus/ContactUs";
import Reports from "../reports/Reports";
import PortfolioSummary from "../portfolio/PortfolioSummary";
import PortfolioSummaryRegion from "../portfolio/PortfolioSummaryRegion";
import GeographyAndSector from "../portfolio/GeographyAndSector";
import Instructions from "../instructions/Instructions";

export default function RouteLinks(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route path="/instructions">
            <Instructions />
          </Route>
          <Route path="/contactus">
            <ContactUs />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route path="/portfoliosummary/geographyandsector">
            <GeographyAndSector />
          </Route>
          <Route path="/portfoliosummary/international">
            <PortfolioSummaryRegion version={"INTERNATIONAL"} />
          </Route>
          <Route path="/portfoliosummary/mauritius">
            <PortfolioSummaryRegion version={"LOCAL"} />
          </Route>
          <Route path="/portfoliosummary">
            <PortfolioSummary />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route component={Error404} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}
