import React, { useState, useEffect, useLayoutEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemButton, Collapse } from "@mui/material";
import PanToolRoundedIcon from "@material-ui/icons/PanToolRounded";
import GradeRoundedIcon from "@material-ui/icons/GradeRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import Router from "../router/Router";
import Logo from "../../images/Confident-menu-traingle.svg";
import Logo2 from "../../images/Confident-Smaller.svg";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Drawer from "@mui/material/Drawer";
import ChatIcon from "@mui/icons-material/Chat";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PieChartIcon from "@mui/icons-material/PieChart";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { Row, Col, Container } from "react-bootstrap";
import Card from "@mui/material/Card";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import AuthUtil from "../../utils/AuthUtil";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { types, actionModes } from "../../state-management/Constants";

import "./Menu.css";

const drawerWidth = 300;
const iconList = [
  <HomeRoundedIcon />,
  <PanToolRoundedIcon />,
  <GradeRoundedIcon />,
];
const sideBarList = ["Home"];

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxHeight: "100vh",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    display: "flex",
    height: "100%",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "#000000",
    boxShadow: "20px 20px 50px darkgrey !important",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    justifyContent: "center",
    alignContent: "center",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    minHeight: "10px",
  },
  content: {
    flexGrow: 1,
    padding: "5px",
    paddingBottom: "60px",
    paddingTop: "0px",
    backgroundColor: "#FFFFFF",
    // height: "100%",
    // minHeight: "max-content",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listItemFont: {
    fontFamily: "Seravek",
    wordBreak: "break-all",
  },
  userEmail: {
    fontFamily: "Seravek",
    wordBreak: "break-all",
    fontSize: "15px",
    color: "#00475a",
    marginLeft: "20px",
    userSelect: "none",
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      dispatch({
        type: types.ScreenSize,
        mode: actionModes.REPLACE,
        payload: [window.innerWidth, window.innerHeight],
      });
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size[0] > 1200 ? false : true;
}

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [activePage, setActivePage] = useState("home");
  const screenSize = useSelector((state) => state[types.ScreenSize]);

  let history = useHistory();

  useEffect(() => {
    AuthUtil.getUserEmail().then((email) => setUserEmail(email));
    if (window.location.pathname.replace("/", "") == "") {
      setActivePage("home");
    } else {
      setActivePage(window.location.pathname.replace("/", ""));
    }
  }, []);

  // console.log(history);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const [menuDropdown, setMenuDropdown] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  function redirect(name) {
    let newLink = "/";
    newLink = newLink + name;
    // window.location.href = newLink;
    setOpen(false);
    setActivePage(name);
    history.push(newLink);
    history.push("/");
    history.goBack();
  }

  function logout() {
    AuthUtil.logout();
  }

  function handleMenuDropdownClick() {
    setMenuDropdown(!menuDropdown);
  }

  function hardReload() {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        if (!cacheName.includes("Cognito")) {
          caches.delete(cacheName);
        }
      });
    });
    new Promise((resolve, reject) => {
      window.location.reload(true);
      resolve();
    }).then(() => {
      window.location.href = "/home";
    });
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>
        <ListItemButton onClick={handleMenuDropdownClick}>
          <ListItemIcon className="icon-col">
            <PieChartIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemFont }}
            primary="Portfolio"
          />
          {menuDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Divider />
        <Collapse in={menuDropdown} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 7 }}
              onClick={() => redirect("portfoliosummary")}
              className={
                "portfoliosummary" === activePage ? "active-page-drawer" : ""
              }
            >
              <ListItemText
                classes={{ primary: classes.listItemFont }}
                primary="Portfolio Summary"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 7 }}
              onClick={() => redirect("portfoliosummary/mauritius")}
              className={
                "portfoliosummary/mauritius" === activePage
                  ? "active-page-drawer"
                  : ""
              }
            >
              <ListItemText
                classes={{ primary: classes.listItemFont }}
                primary="Mauritius"
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 7 }}
              onClick={() => redirect("portfoliosummary/international")}
              className={
                "portfoliosummary/international" === activePage
                  ? "active-page-drawer"
                  : ""
              }
            >
              <ListItemText
                classes={{ primary: classes.listItemFont }}
                primary="International"
              />
            </ListItemButton>
          </List>
        </Collapse>
        <Divider />
        <ListItemButton
          onClick={() => redirect("reports")}
          className={"reports" === activePage ? "active-page-drawer" : ""}
        >
          <ListItemIcon className="icon-col">
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemFont }}
            primary="Reports"
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => redirect("home")}
          className={
            "home" === activePage || activePage === ""
              ? "active-page-drawer"
              : ""
          }
        >
          <ListItemIcon className="icon-col">
            <AnnouncementIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemFont }}
            primary="News Communications"
          />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => redirect("contactus")}
          className={"contactus" === activePage ? "active-page-drawer" : ""}
        >
          <ListItemIcon className="icon-col">
            <ChatIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemFont }}
            primary="Contact Us"
          />
        </ListItemButton>
        <Divider />
        <div className="bottom-section">
          {screenSize[0] <= 1200 ? (
            <>
              <Row>
                <Col>
                  <Typography className={classes.userEmail}>
                    {userEmail.split("@")[0]}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography className={classes.userEmail}>
                    @{userEmail.split("@")[1]}
                  </Typography>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col>
                <Typography className={classes.userEmail}>
                  {userEmail}
                </Typography>
              </Col>
            </Row>
          )}

          <ListItemButton onClick={() => setOpenDialog(true)}>
            <ListItemIcon className="icon-col">
              <ExitToAppRoundedIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemFont }}
              primary="Logout"
            />
          </ListItemButton>
        </div>
      </List>
    </div>
  );

  return (
    <>
      <div style={{ width: "100vw", maxWidth: "100vw" }}>
        <div className={classes.root}>
          <CssBaseline />
          {useWindowSize() && (
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                // [classes.appBarShift]: open,
              })}
              style={{ background: "#FFF", bottom: "0", top: "auto" }}
            >
              <Toolbar>
                <Container fluid className="no-padding">
                  <Row
                    style={{ width: "98%" }}
                    className="center-items no-padding icon-row"
                  >
                    <Col className="no-padding">
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(!open)}
                        className="icon-col"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Col>
                    <Col className="no-padding">
                      <Row>
                        <Col>
                          <IconButton
                            className={
                              activePage.includes("portfoliosummary")
                                ? "active-page no-padding"
                                : "icon-col"
                            }
                            onClick={() => redirect("portfoliosummary")}
                          >
                            <PieChartIcon />
                          </IconButton>
                        </Col>
                      </Row>
                      {activePage.includes("portfoliosummary") && (
                        <Row>
                          <Col className="icon-label active-page">
                            Portfolio
                          </Col>
                        </Row>
                      )}
                    </Col>
                    <Col className="no-padding">
                      <Row>
                        <Col>
                          <IconButton
                            className={
                              "reports" === activePage
                                ? "active-page no-padding"
                                : "icon-col"
                            }
                            onClick={() => redirect("reports")}
                          >
                            <LibraryBooksIcon />
                          </IconButton>
                        </Col>
                      </Row>
                      {"reports" === activePage && (
                        <Row>
                          <Col className="icon-label active-page">Reports</Col>
                        </Row>
                      )}
                    </Col>
                    <Col className="no-padding no-padding">
                      <Row>
                        <Col>
                          {" "}
                          <IconButton
                            className={
                              "home" === activePage || activePage === ""
                                ? "active-page no-padding"
                                : "icon-col"
                            }
                            onClick={() => redirect("home")}
                          >
                            <AnnouncementIcon />
                          </IconButton>
                        </Col>
                      </Row>
                      {"home" === activePage && (
                        <Row>
                          <Col className="icon-label active-page">News</Col>
                        </Row>
                      )}
                    </Col>
                    <Col className="no-padding">
                      <Row>
                        <Col>
                          <IconButton
                            className={
                              "contactus" === activePage
                                ? "active-page"
                                : "icon-col"
                            }
                            onClick={() => redirect("contactus")}
                          >
                            <ChatIcon />
                          </IconButton>
                        </Col>
                      </Row>
                      {"contactus" === activePage && (
                        <Row>
                          <Col className="icon-label active-page">Contact</Col>
                        </Row>
                      )}
                    </Col>
                    <Col xs={2} className="no-padding">
                      <IconButton onClick={hardReload}>
                        <img
                          src={Logo}
                          style={{ cursor: "pointer" }}
                          alt={"Confident Asset Management"}
                          className="logo-top-menu"
                        ></img>
                      </IconButton>
                    </Col>
                  </Row>
                </Container>
              </Toolbar>
            </AppBar>
          )}

          <main
            style={{ width: "100%" }}
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <div className={"screen-container"}>
              <Container fluid>
                <Row>
                  {!useWindowSize() && (
                    <Drawer
                      sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                          width: drawerWidth,
                          boxSizing: "border-box",
                        },
                      }}
                      variant="persistent"
                      anchor="left"
                      open={true}
                    >
                      <img
                        src={Logo2}
                        style={{ cursor: "pointer" }}
                        alt={"Confident Asset Management"}
                        className="logo-side-menu"
                      ></img>
                      <Divider />
                      {list("left")}
                    </Drawer>
                  )}
                  <Col
                    className={
                      useWindowSize() === true
                        ? ""
                        : "component-background center-items"
                    }
                  >
                    <div className="cssloader" id="loader">
                      <div className="sh1"></div>
                      <div className="sh2"></div>
                      <h4 className="lt">loading</h4>
                    </div>
                    <div className="main-router">
                      {useWindowSize() === false ? (
                        <Card className="component-card">
                          <Router />
                        </Card>
                      ) : (
                        <>
                          <Router />
                        </>
                      )}
                    </div>
                  </Col>
                  {/* <Col xs={0} md={4} lg={4}></Col> */}
                </Row>
              </Container>
            </div>
          </main>

          {useWindowSize() && (
            <SwipeableDrawer
              anchor={"left"}
              open={open}
              onClose={toggleDrawer}
              onOpen={toggleDrawer}
              className={classes.drawer}
            >
              {list("left")}
            </SwipeableDrawer>
          )}
          <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <h4 className="heading heading-font">Logout?</h4>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                className="paragraph-font"
              >
                Are you sure you would like to Logout ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={logout}
                variant="contained"
                className="login-button"
              >
                Yes
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                className="logout-button"
                autoFocus
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}
