import React from "react";
import Error404 from "./Error-404";
import Error500 from "./Error-500";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, type: "" };
    this.type = "";
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    if (error.name === "404") {
      this.setState({ hasError: true, type: "404" });
    } else if(error.name === "500") {
      this.setState({ hasError: true, type: "500" });
    }
    //this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.state.type === "404") {
        return <Error404 />;
      } else if(this.state.type === "500"){
        return <Error500 />;
      }
      else
      {
        return (<></>)
      }
    }

    return this.props.children;
  }
}
