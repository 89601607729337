import React, { useState, useEffect } from "react";
import Login from "./login";
import ConfidentLogo from "../../images/Confident-Smaller.svg";
import { Row, Col, Container } from "react-bootstrap";
import ResetPassword from "./resetPassword";
import ForgotPassword from "./forgotPassword";
import Api from "../../api/Api";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import "./login.css";
import { Card } from "antd";
import Background from "../../images/ConfidentLoginBackground.svg";

export default function LoginBase() {
  const [loginState, setLoginState] = useState("Login");
  const [cognitoUser, setCognitoUser] = useState({});
  const [termsDialog, setTermsDialog] = useState(false);

  const handleCloseTermsDialog = () => {
    setTermsDialog(false);
  };

  // useEffect(() => {
  //   window.location.reload(true);
  // }, []);

  function getComponent() {
    switch (loginState) {
      case "Login":
        return (
          <Login
            changeLoginState={setLoginState}
            setCognitoUser={setCognitoUser}
            checkTermsAndConditions={checkTermsAndConditions}
          />
        );
      case "ResetPassword":
        return (
          <ResetPassword
            changeLoginState={setLoginState}
            cognitoUser={cognitoUser}
            checkTermsAndConditions={checkTermsAndConditions}
          />
        );
      case "ForgotPassword":
        return <ForgotPassword changeLoginState={setLoginState} />;
      default:
        return (
          <Login
            changeLoginState={setLoginState}
            setCognitoUser={setCognitoUser}
            checkTermsAndConditions={checkTermsAndConditions}
          />
        );
    }
  }

  async function checkTermsAndConditions() {
    Api.hasUserAcceptedTermsConditions().then((res) => {
      if (res.data === true) {
        window.location.href = "/home";
      } else {
        setTermsDialog(true);
      }
    });
  }

  function openTermsConditions() {
    Api.getTermsAndConditions().then((res) => {
      let terms = res.data;
      window.open(terms.url, "_blank");
    });
  }

  function handleAccept() {
    // make call to accept the terms and conditions
    Api.acceptTermsConditions().then((res) => {
      if (res.data === true) {
        setTermsDialog(false);
        window.location.href = "/home";
      }
    });
  }

  return (
    <div className="login-background">
      <Container>
        <Row className="add-top-margin">
          <Col className="center-items">
            <img
              src={ConfidentLogo}
              style={{ cursor: "pointer" }}
              alt={"Confident Asset Management"}
              className="logo-login"
            ></img>
          </Col>
        </Row>
        <Row className="add-top-margin ">
          {/* <Col xs={0} md={4} lg={4}></Col> */}
          <Col
            // xs={12}
            // md={4}
            // lg={4}
            className="center-items full-height align-items-vertical-center"
          >
            <div className="cssloader" id="loader">
              <div className="sh1"></div>
              <div className="sh2"></div>
              <h4 className="lt">loading</h4>
            </div>
            <Card className="login-card">{getComponent()}</Card>
          </Col>
          {/* <Col xs={0} md={4} lg={4}></Col> */}
        </Row>
        <Dialog
          open={termsDialog}
          onClose={handleCloseTermsDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You have not accepted the{" "}
              <a href="" onClick={openTermsConditions}>
                terms and conditions
              </a>
              .
              <br />
              By clicking Accept you have indicated that you have read and
              accepted the{" "}
              <a href="" onClick={openTermsConditions}>
                terms and conditions
              </a>
              .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleAccept}
              variant="contained"
              className="login-button"
            >
              Accept
            </Button>
            <Button
              onClick={handleCloseTermsDialog}
              variant="contained"
              className="login-button"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
