import React, { useEffect } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import Reducer from "./state-management/Reducer";
import ErrorBoundary from "./components/error/ErrorBoundary";
import { Auth } from "aws-amplify";
import AuthUtil from "./utils/AuthUtil";
import Nav from "./components/navigation/Menu";
import LoginHome from "./components/login/loginHome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";

const store = createStore(Reducer);

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

const App = () => {
  const handleOnIdle = (event) => {
    signOut();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      confirmLoggedIn();
    }
  }, []);

  async function confirmLoggedIn() {
    AuthUtil.getIsLoggedIn().then(async (loggedIn) => {
      if (!loggedIn) {
        try {
          await signOut();
        } catch (ex) {
          window.location.href = "/login";
        }
      }
    });
  }

  async function signOut() {
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
    await Auth.signOut({ global: true });
    window.location.href = "/login";
  }

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginHome />
          </Route>
          <Route path="/">
            <Nav />
          </Route>
          <Route component={Nav} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default AppWrapper;
