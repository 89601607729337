const _MS_PER_DAY = 1000 * 60 * 60 * 24;
const _MS_PER_HOUR = 1000 * 60 * 60;
export default {
  checkDateDifference(date1, date2) {
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate()
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate()
    );

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  },
  checkHourDifference(date1, date2) {
    return Math.floor(Math.abs(date2.getTime() - date1.getTime()) / 3600000);
  },
  checkMinuteDifference(date1, date2) {
    return Math.floor(Math.abs(date2.getTime() - date1.getTime()) / 60000);
  },
  getFriendlyTime(date1, date2) {
    let hourDiff = this.checkHourDifference(date1, date2);
    if (hourDiff > 0) {
      if (hourDiff == 1) {
        return hourDiff + " hour ago";
      } else {
        return hourDiff + " hours ago";
      }
    } else {
      return this.checkMinuteDifference(date1, date2) + " minutes ago";
    }
  },
  getFriendlyDateTime(date) {
    let today = new Date();
    let passedDate = new Date(date + "z");
    let dateDiff = this.checkDateDifference(passedDate, today);
    if (dateDiff > 1) {
      return passedDate.toISOString().split("T")[0]; //format
    } else if (dateDiff === 1) {
      return "Yesterday " + passedDate.toLocaleTimeString(); //with time
    } else {
      return this.getFriendlyTime(passedDate, today);
    }
  },

  getReportDate(date) {
    let parsedDate = new Date(date);
    return (
      parsedDate.toLocaleString("default", { month: "long" }) +
      " " +
      parsedDate.getFullYear().toString()
    );
  },
};
