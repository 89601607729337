import React from "react";
import DateUtils from "../../utils/DateUtils";
import { Card, Button, Divider } from "antd";
import { Row, Col } from "react-bootstrap";
import General from "../../utils/General";

import "./Reports.css";
import Api from "../../api/Api";

export default function ReportCard({ report }) {
  function getReportName() {
    if (!report.externalSource) {
      return `Confident (${report.reportCurrency})`;
    } else if (report.externalSource === "MCB") {
      return "MCB";
    } else if (report.externalSource === "Rothschild") {
      return "EDR";
    }
  }

  function openReport() {
    General.getIosWarning();
    return Api.getReportUrl(report.reportKey).then((res) => {
      window.open(res["tempUrl"], "_blank");
    });
  }

  //   function getDownloadCount(){
  //     let keys = Object.keys(report.reports);
  //     let count = keys.length;
  //     if (count == 1)
  //       return "1 report";
  //     else
  //       return `${count} reports`;
  //   }

  return (
    <Card
      title={DateUtils.getReportDate(report.reportDate)}
      bordered={false}
      style={{ width: "100%", border: "2px solid grey" }}
      headStyle={{ backgroundColor: "#00475a", color: "#FFF" }}
    >
      <Row>
        <Col xs={10}>{getReportName()}</Col>
        <Col xs={2}>
          <Button shape="circle" onClick={openReport}>
            <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
          </Button>
        </Col>
      </Row>
      {report.custodianReports.length > 0 &&
        report.custodianReports.map((custodianReport, index) => {
          return (
            <Row key={"custodian-report-" + index}>
              <Col xs={10}>
                {custodianReport.externalSource} {custodianReport.accountNumber}{" "}
                ({custodianReport.reportCurrency}) Report
              </Col>
              <Col xs={2} onClick={openReport}>
                <Button shape="circle">
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                </Button>
              </Col>
            </Row>
          );
        })}
      {/* <Divider />
      <Row>
          <Col>
          {getDownloadCount()}
          </Col>
      </Row> */}
    </Card>
  );
}
