import React, { useEffect, useState } from "react";
import { Row, Col, Pagination } from "react-bootstrap";
import Api from "../../api/Api";
import PortfolioSelectionComponent from "./PortfolioSelectionComponent";
import { Card, Button, Table } from "antd";
import GeneralUtils from "../../utils/General";
import ImageUtils from "../../utils/ImageUtils";
import General from "../../utils/General";

export default function PortfolioSummaryRegion({ version }) {
  const [assetClassData, setAssetClassData] = useState([]);
  const [currency, setCurrency] = useState("MUR");
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const [assetClassTotals, setAssetClassTotals] = useState(0);
  const [selectedAssetClass, setSelectedAssetClass] = useState("None");
  const [tableData, setTableData] = useState([]);
  const [selectedSecurity, setSelectedSecurity] = useState(null);
  const [offDropdown, setOffDropdown] = useState("");

  useEffect(() => {
    return () => {
      setAssetClassData([]);
      setCurrency("MUR");
      setSelectedPortfolios([]);
      setAssetClassTotals(0);
      setSelectedAssetClass("None");
      setTableData([]);
      setSelectedSecurity(null);
      setOffDropdown("");
    };
  }, []);

  useEffect(() => {
    if (selectedPortfolios.length > 0) {
      General.showLoader();
      Api.getPortfolioAggregatesByAssetClasses(
        null,
        currency,
        version,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      )
        .then((results) => {
          setAssetClassData(results);
          let assetClassTotal = results.reduce((prev, next) => {
            return (prev = prev + next.total);
          }, 0);
          setAssetClassTotals(assetClassTotal);
        })
        .finally(() => General.closeLoader());
    }
  }, [offDropdown, currency]);

  useEffect(() => {
    if (selectedAssetClass !== "None") {
      Api.getPortfolioItems(
        selectedAssetClass,
        currency,
        version,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      ).then((results) => {
        let total = 0;
        if (selectedAssetClass === "Cash") {
          total = results.reduce((prev, next) => {
            return (prev = prev + next.marketValue);
          }, 0);
        } else {
          total = results.reduce((prev, next) => {
            return (prev = prev + next.marketValueDisplayCurrency);
          }, 0);
        }
        let tableData = results.map((element, index) => {
          let name = "";
          let quantity = element["quantity"]?.toLocaleString();
          let marketValue =
            element["marketValueDisplayCurrency"].toLocaleString();
          let marketValuePercentage = element["marketValueDisplayCurrency"];
          let listPrice = "";
          if (element.equityItem) {
            name = element.equityItem.name;
            listPrice = element["listPrice"];
          } else if (element.cashAccount) {
            marketValuePercentage = element.marketValue;
            marketValue = "MUR " + element.marketValueMUR.toLocaleString();
            quantity = element.amount.toLocaleString();
            name = element.cashAccount.name;
            listPrice = "";
          } else if (element.fixedIncomeItem) {
            name = element.fixedIncomeItem.name;
            listPrice = element["price"];
          } else if (element.preciousMetalItem) {
            name = element.preciousMetalItem.name;
            listPrice = element["price"];
          } else if (element.alternativeInvestmentItem) {
            name = element.alternativeInvestmentItem.name;
            listPrice = element["price"];
          }
          return {
            key: "mauritius-item-" + index,
            name: name,
            portfolioName: element.portfolio.name,
            quantity: quantity,
            listPrice: element.currency + " " + listPrice.toLocaleString(),
            percentage: (
              (marketValuePercentage / total) *
              100
            ).toLocaleString(),
            marketValue: marketValue,
            expanded: (
              <Card
                title={name}
                // extra={
                //   <Button shape="circle" onClick={() => setSelectedSecurity(null)}>
                //     {ImageUtils.getCloseDetails()}
                //   </Button>
                // }
                className="add-top-margin break-word"
                headStyle={GeneralUtils.getCardTitleStyle()}
              >
                {!element.cashAccount && (
                  <>
                    <Row className="add-top-margin">
                      <Col xs={6}>Quantity:</Col>
                      <Col xs={6}>{quantity}</Col>
                    </Row>
                    <Row className="add-top-margin">
                      <Col xs={6}>Price:</Col>
                      <Col xs={6}>
                        {element.currency + " " + listPrice.toLocaleString()}
                      </Col>
                    </Row>
                  </>
                )}
                {element.cashAccount && (
                  <Row className="add-top-margin">
                    <Col xs={6}>Amount:</Col>
                    <Col xs={6}>{quantity}</Col>
                  </Row>
                )}
                <Row className="add-top-margin">
                  <Col xs={6}>% of Asset Class:</Col>
                  <Col xs={6}>
                    {((marketValuePercentage / total) * 100).toLocaleString()}
                  </Col>
                </Row>
                <Row className="add-top-margin">
                  <Col xs={6}>Market Value (MUR):</Col>
                  <Col xs={6}>{marketValue}</Col>
                </Row>
              </Card>
            ),
          };
        });
        setTableData(tableData);
      });
    }
  }, [selectedAssetClass]);

  const columns = [
    {
      title: "Description",
      dataIndex: "name",
      key: "description",
      render: (text, record) => (
        <>
          <Row>
            <Col>{record.portfolioName}</Col>
          </Row>
          <Row>
            <Col>{record.name}</Col>
          </Row>
        </>
      ),
    },
  ];

  function changeOffDropDown() {
    setOffDropdown(new Date());
  }

  return (
    <>
      <Row>
        <Col className="heading heading-font">
          {version === "LOCAL" ? "Mauritius" : "International"} Portfolio
          Summary
        </Col>
      </Row>
      <PortfolioSelectionComponent
        setCurrency={setCurrency}
        currency={currency}
        selectedPortfolios={selectedPortfolios}
        setSelectedPortfolios={setSelectedPortfolios}
        offDropdown={changeOffDropDown}
      />
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col>
          <Card
            title={"Investment Summary"}
            bordered={false}
            style={{ width: "100%" }}
            headStyle={GeneralUtils.getCardTitleStyle()}
          >
            {assetClassData.map((assetClass, index) => {
              return (
                <Card
                  title={`${assetClass.name} (${assetClass.currency})`}
                  type="inner"
                  headStyle={
                    assetClass.name === selectedAssetClass
                      ? GeneralUtils.getCardTitleStyle()
                      : {}
                  }
                  onClick={() => {
                    setSelectedAssetClass(assetClass.name);
                    setSelectedSecurity(null);
                  }}
                  extra={
                    <Button shape="circle">
                      {ImageUtils.getAssetClassImage(assetClass.name)}
                    </Button>
                  }
                  className={
                    assetClass.name === selectedAssetClass
                      ? "selected-asset-class-table pointer-mouse"
                      : "pointer-mouse"
                  }
                  key={"mauritius-" + index}
                >
                  <Row>
                    <Col xs={7}>
                      {(
                        Math.round(assetClass.total * 100) / 100
                      ).toLocaleString()}
                    </Col>
                    <Col xs={5} className="right-align-text">
                      {(
                        Math.round(
                          (assetClass.total / assetClassTotals) * 100 * 100
                        ) / 100
                      ).toLocaleString()}{" "}
                      %
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <Card
              title={`Total (${currency})`}
              type="inner"
              extra={ImageUtils.getTotalImage()}
            >
              <Row>
                <Col xs={7}>{assetClassTotals.toLocaleString()}</Col>
                <Col xs={5} className="right-align-text">
                  100 %
                </Col>
              </Row>
            </Card>
          </Card>
        </Col>
      </Row>
      {selectedAssetClass !== "None" && !selectedSecurity && (
        <Row className="add-top-margin">
          <Col>
            <Card
              title={selectedAssetClass}
              bordered={true}
              style={{ width: "100%" }}
              headStyle={GeneralUtils.getCardTitleStyle()}
            >
              <Table
                dataSource={tableData}
                columns={columns}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => record.expanded,
                }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
