import React from "react";
import { Row, Col } from "react-bootstrap";

export default function Instructions() {
  return (
    <>
      <Row>
        <Col className="heading heading-font">
          Adding Confident Asset Management to your Home Screen.
        </Col>
      </Row>
      <Row className="heading-font">
        <Col xs={12} className="small-heading">
          Android:
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col xs={12}>
          <ol>
            <li>Please ensure that you are using Google Chrome.</li>
            <li>
              Press the three dots on the top right next to the search bar.
            </li>
            <li>
              Scroll until you can see "Add to home screen" and select that
              option.
            </li>
            <li>Press "Add" when the popup appears.</li>
            <li>Close Google Chrome and go to your home screen.</li>
            <li>Find the Confident app!</li>
          </ol>
        </Col>
      </Row>
      <Row className="heading-font">
        <Col xs={12} className="small-heading">
          IOS:
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col xs={12}>
          <ol>
            <li>Please ensure that you are using Safari.</li>
            <li>Press the share button at the bottom of the screen.</li>
            <li>
              Scroll until you can see "Add to Home Screen" and select that
              option.
            </li>
            <li>Press "Add" when the popup appears.</li>
            <li>Close Safari and go to your home screen.</li>
            <li>Find the Confident app!</li>
          </ol>
        </Col>
      </Row>
    </>
  );
}
