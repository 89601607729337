import React from "react";
import { Row, Col } from "react-bootstrap";
import ImageUtil from "../../utils/ImageUtils";
import DateUtil from "../../utils/DateUtils";
import General from "../../utils/General";

import "./Home.css";

export default function NewsFeedItem({ newsFeed }) {
  function downloadFile() {
    General.getIosWarning();
    window.open(newsFeed.file.url, "_blank");
  }

  return (
    <Row className="news-feed-item-width">
      <Col className="news-feed-item">
        <Row className="news-feed-heading heading-font">
          <Col xs={2}>{ImageUtil.getNotificationImage(newsFeed.type)}</Col>
          <Col xs={6} className="center-items">
            {newsFeed.type}
          </Col>
          <Col xs={4} className="news-feed-time">
            {DateUtil.getFriendlyDateTime(newsFeed.datePosted)}
          </Col>
        </Row>
        <Row className="news-feed-text paragraph-font">
          <Col xs={10} lg={11}>
            {newsFeed.message}
          </Col>
          <Col
            xs={2}
            lg={1}
            className="align-items-vertical-center pointer-mouse"
            onClick={downloadFile}
          >
            {newsFeed.file.key !== "" ? (
              <i className="fa fa-file" aria-hidden="true"></i>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
