import config from "../config/config";
import axios from "axios";
import { Auth } from "aws-amplify";

const portfolioUrl = config.baseUrl + "/portfolios";
const portfolioManagementUrl = config.baseUrl + "/portfolios/management";
const portfolioManagementDashboardUrl =
  config.baseUrl + "/portfolios/dashboard";
const clientUrl = config.baseUrl + "/clients";
const accountsUrl = clientUrl + "/accounts";
const clientRefreshUrl = clientUrl + "/refresh";
const cModelUrl = config.baseUrl + "/cmodels";
const securityManagementUrl = cModelUrl + "/securities";
const fileUrl = config.baseUrl + "/files";
const healthUrl = config.baseUrl + "/health";
const userUrl = config.baseUrl + "/users";
const reportingUrl = config.baseUrl + "/reporting";
const reportsUrl = config.baseUrl + "/reports";
const tradingUrl = config.baseUrl + "/trading";
const emailUrl = config.baseUrl + "/users";
const feesUrl = config.baseUrl + "/fees";
const exchangeUrl = config.baseUrl + "/exchangerate";
const complianceUrl = config.baseUrl + "/compliance";

axios.interceptors.request.use(async function (config) {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default {
  getTermsAndConditions: async function () {
    try {
      const params = {
        method: "get",
        url: userUrl + "/terms",
      };
      const apiResponse = await axios(params);
      return apiResponse;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  hasUserAcceptedTermsConditions: async function () {
    try {
      // let result = await axios.get(userUrl + "/terms/accepted");
      let result = await axios.get(userUrl + "/terms/accepted");
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  acceptTermsConditions: async function () {
    try {
      let result = await axios.put(userUrl + "/terms", null);
      return result;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getNewsComms: async function () {
    try {
      const params = {
        method: "get",
        url: fileUrl + "/communication/mobile",
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getUserClients: async function () {
    try {
      const params = {
        method: "get",
        url: clientUrl + "/UserClients",
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getReportTypes: async function () {
    try {
      const params = {
        method: "get",
        url: reportsUrl + "/reporttypes",
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getClientReportByType: async function (clientNumber, reportType) {
    try {
      const params = {
        method: "get",
        url: reportsUrl + `/${clientNumber}/${reportType}`,
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getReportUrl: async function (reportKey) {
    try {
      const params = {
        method: "get",
        url: reportingUrl + `/url/${encodeURIComponent(reportKey)}`,
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getUserPortfolios: async function () {
    try {
      const params = {
        method: "get",
        url: portfolioUrl + `/UserPortfolios`,
      };
      const apiResponse = await axios(params);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  generatePdf: async function (
    reportType,
    currency,
    userId,
    clientNumber,
    portfolioIds,
    fromDate,
    toDate,
    displayLocalPerformance,
    displayInternationalPerformance,
    displayMonthlyPerformance,
    displayLineItems,
    isUnreconciled,
    inceptionPerf
  ) {
    // console.log(portfolioIds);
    // var params = new URLSearchParams(Object.entries(queryParams));
    // // portfolioIds.forEach((element) => {
    // //   params.append("portfolioIds", element);
    // // });
    // var request = {
    //   params: params,
    // };
    // console.log(params);
    // try {
    //   const apiResponse = await axios.post(url, request);

    //   // const apiResponse = await axios(params);
    //   return apiResponse.data;
    // } catch (err) {
    //   console.log(err);
    //   return err;
    // }

    let queryParams = {
      userId: userId,
      clientNumber: clientNumber,
      fromDate: fromDate,
      toDate: toDate,
      currency: currency ? "MUR" : currency,
      displayLocalPerformance: displayLocalPerformance,
      displayInternationalPerformance: displayInternationalPerformance,
      displayMonthlyPerformance: displayMonthlyPerformance,
      displayLineItems: displayLineItems,
      isUnreconciled: isUnreconciled,
      inceptionPerf: inceptionPerf,
    };

    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });
    var request = {
      params: params,
    };

    let url = reportsUrl + "/generate/";
    if (userId) {
      url += `user/${reportType}`;
    }
    if (clientNumber) {
      url += `client/${reportType}`;
    }
    if (portfolioIds) {
      url += `selectedPortfolios/${reportType}`;
    }

    try {
      const apiResponse = await axios.post(url, {}, request);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getPortfolioAggregatesByLocality: async function (
    clientNumber,
    currency,
    portfolioIds,
    toDate
  ) {
    let queryParams = {
      clientNumber: clientNumber,
      currency: currency,
      date: toDate,
    };
    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });
    var request = {
      params: params,
    };

    try {
      const apiResponse = await axios.get(
        portfolioUrl + "/localities",
        request
      );
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getPortfolioAggregatesByAssetClasses: async function (
    clientNumber,
    displayCurrency,
    locality,
    portfolioIds,
    toDate
  ) {
    let queryParams = {
      clientNumber: clientNumber,
      currency: displayCurrency,
      locality: locality,
      date: toDate,
    };

    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });
    var request = {
      params: params,
    };

    try {
      const apiResponse = await axios.get(
        portfolioUrl + "/assetclasses",
        request
      );
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getPortfolioItems: async function (
    assetClass,
    displayCurrency,
    locality,
    portfolioIds,
    date
  ) {
    let queryParams;
    if (date) {
      queryParams = {
        displayCurrency: displayCurrency,
        locality: locality,
        date: date,
      };
    } else {
      queryParams = {
        displayCurrency: displayCurrency,
        locality: locality,
      };
    }

    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });

    var request = {
      params: params,
    };

    let url = "equityItems";
    switch (assetClass) {
      case "Fixed Income":
        url = "fixedIncomeItems";
        break;
      case "Commodities":
        url = "preciousMetalItems";
        break;
      case "Cash":
        url = "cashItems";
        break;
      case "Alternative Inv":
        url = "alternativeInvestmentsItems";
        break;
      default:
        url = "equityItems";
        break;
    }

    try {
      const apiResponse = await axios.get(portfolioUrl + "/" + url, request);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getPortfolioAggregatesByGeography: async function (
    currency,
    portfolioIds,
    toDate,
    locality
  ) {
    let queryParams = {
      currency: currency,
      date: toDate,
      locality: locality,
    };

    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });
    var request = {
      params: params,
    };

    try {
      const apiResponse = await axios.get(
        portfolioUrl + "/geographies",
        request
      );
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  getPortfolioAggregatesBySector: async function (
    currency,
    portfolioIds,
    toDate,
    locality
  ) {
    let queryParams = {
      currency: currency,
      date: toDate,
      locality: locality,
    };

    var params = new URLSearchParams(Object.entries(queryParams));
    portfolioIds.forEach((element) => {
      params.append("portfolioIds", element);
    });
    var request = {
      params: params,
    };

    try {
      const apiResponse = await axios.get(portfolioUrl + "/sectors", request);
      return apiResponse.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
};
