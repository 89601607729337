import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Api from "../../api/Api";
import { Select } from "antd";
import ReportCard from "./ReportCard";

import "./Reports.css";
import General from "../../utils/General";

const { Option } = Select;
export default function Reports() {
  const [clients, setClients] = useState([]);
  const [reportTypes, setReportTypes] = useState([
    { id: 1, name: "PortfolioSummary" },
  ]);
  const [reportsForClient, setReportsForClient] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");

  useEffect(() => {
    General.showLoader();
    Api.getUserClients()
      .then((results) => {
        setClients(results);
        Api.getReportTypes()
          .then((resultsReports) => {
            setReportTypes(resultsReports);
            if (results.length > 0) {
              setSelectedClient(results[0].clientNumber);
              getReportsForClient(results[0].clientNumber);
            }
          })
          .catch((err) => {
            setReportTypes([{ id: 1, name: "PortfolioSummary" }]);
          });
      })
      .finally(() => General.closeLoader());
  }, []);

  function selectOnChange(value) {
    //   setSelectedClient(value)
    getReportsForClient(value);
    setSelectedClient(value);
  }

  function getReportsForClient(value) {
    let promiseArray = [];
    reportTypes.forEach((element) => {
      promiseArray.push(Api.getClientReportByType(value, element.name));
    });
    Promise.all(promiseArray).then((results) => {
      let reportArray = [];
      results.forEach((element, index) => {
        reportArray.push({ type: reportTypes[index].name, reports: element });
      });
      setReportsForClient(reportArray);
    });
  }

  function formatReportType(report) {
    return report.replace(/([A-Z])/g, " $1").trim();
  }

  return (
    <>
      <Row>
        <Col className="heading heading-font">Reports</Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center">
        <Col xs={12}>
          {clients.length > 0 ? (
            <Select
              className="full-width"
              showSearch
              style={{ width: 200 }}
              placeholder="Select a client"
              optionFilterProp="children"
              onChange={selectOnChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedClient}
            >
              {clients.map((client, index) => {
                return (
                  <Option value={client.clientNumber} key={"reports-" + index}>
                    {client.name}
                  </Option>
                );
              })}
            </Select>
          ) : (
            <Row>
              <Col className="heading heading-font">
                You have no clients, please contact a system administrator if
                you believe this is incorrect.
              </Col>{" "}
            </Row>
          )}
        </Col>
      </Row>
      {reportsForClient.length > 0 &&
        reportsForClient.map((report, index) => {
          return (
            <Row
              className="paragraph-font align-items-vertical-center add-top-margin"
              key={"client-report-type-" + index}
            >
              <Col xs={12}>
                <span className="small-heading heading-font">
                  {formatReportType(report.type)}
                </span>

                {report.reports.length > 0 ? (
                  report.reports.map((clientReport, index2) => {
                    return (
                      <Row
                        key={"client-report-" + index2}
                        className="add-top-margin "
                      >
                        <Col className="align-items-vertical-center">
                          <ReportCard report={clientReport} />
                        </Col>
                      </Row>
                    );
                  })
                ) : (
                  <Row>
                    <Col className="heading heading-font">
                      There are no reports for this client, please contact a
                      system administrator if you believe this is incorrect.
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          );
        })}
    </>
  );
}
