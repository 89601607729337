import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import { Input, Button } from "antd";
import AuthUtil from "../../utils/AuthUtil";
import { UserOutlined } from "@ant-design/icons";
import GeneralUtils from "../../utils/General";

import "./login.css";

export default function Login({
  changeLoginState,
  setCognitoUser,
  checkTermsAndConditions,
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState({
    roles: false,
    other: false,
  });

  function login() {
    GeneralUtils.showLoader();
    AuthUtil.login(username, password)
      .then(async (result) => {
        if (result.challengeName) {
          if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCognitoUser(result);
            changeLoginState("ResetPassword");
          }
        } else if (result.ChallengeName) {
          setCognitoUser(result);
          changeLoginState("ResetPassword");
        }
        let roles = await AuthUtil.getUserGroups();
        if (roles.length === 0 || !roles.length) {
          setLoginError({
            roles: true,
            other: loginError.other,
          });
        } else {
          if (result.challengeName) {
            if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
              setCognitoUser(result);
              changeLoginState("ResetPassword");
            }
          } else if (result.ChallengeName) {
            setCognitoUser(result);
            changeLoginState("ResetPassword");
          } else {
            checkTermsAndConditions();
          }
        }
      })
      .catch((error) => {
        console.log("LOGIN ERROR:", error);
        if (error.code === "PasswordResetRequiredException") {
          changeLoginState("ResetPassword");
        } else {
          setLoginError({
            roles: loginError.roles,
            other: true,
          });
        }
      })
      .finally(() => GeneralUtils.closeLoader());
  }

  function changeUsername(e) {
    setUsername(e.target.value);
    setLoginError({
      roles: false,
      other: false,
    });
  }

  function changePassword(e) {
    setPassword(e.target.value);
    setLoginError({
      roles: false,
      other: false,
    });
  }

  function forgotPassword() {
    changeLoginState("ForgotPassword");
  }

  return (
    <div>
      <Row>
        <Col className="heading heading-font">Login</Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Email"
            suffix={<UserOutlined className="site-form-item-icon" />}
            onChange={changeUsername}
          />
        </Col>
      </Row>
      <Row className="add-small-top-margin">
        <Col>
          <Input.Password placeholder="Password" onChange={changePassword} />
        </Col>
      </Row>
      <Row className="add-small-top-margin center-items">
        <Col>
          <Button onClick={login} className="login-button">
            Login
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="info-link" onClick={forgotPassword}>
          Forgot password?
        </Col>
      </Row>
      {loginError.roles && (
        <Row>
          <Col className="error-message">
            Could NOT find roles linked to you. Please contact your system
            administrator.
          </Col>
        </Row>
      )}
      {loginError.other && (
        <Row>
          <Col className="error-message">
            Please ensure that you entered the correct email and password.
          </Col>
        </Row>
      )}
    </div>
  );
}
