import React, { useEffect, useState } from "react";
import Api from "../../api/Api";
import NewsFeedItem from "./NewsFeedItem";
import { Row, Col } from "react-bootstrap";
import { Button, notification } from "antd";
import { isMobile, isTablet } from "react-device-detect";
import DateUtils from "../../utils/DateUtils";
import GeneralUtils from "../../utils/General";
import { useSelector } from "react-redux";
import { types } from "../../state-management/Constants";
import Logo2 from "../../images/Confident-Smaller.svg";
import { Divider } from "@material-ui/core";

import "./Home.css";

export default function Home() {
  const [newsComms, setNewsComms] = useState([]);
  const screenSize = useSelector((state) => state[types.ScreenSize]);

  useEffect(() => {
    GeneralUtils.showLoader();
    Api.getNewsComms()
      .then((results) => {
        setNewsComms(results);
      })
      .finally(() => GeneralUtils.closeLoader());
  }, []);

  useEffect(() => {
    if ((isMobile || isTablet) && checkNotificationDisplay()) {
      localStorage.setItem("displayNotification", new Date());
      openNotification();
    }
  }, []);

  function checkNotificationDisplay() {
    let notification = localStorage.getItem("displayNotification");
    if (!notification) {
      return true;
    }
    let today = new Date();
    let passedDate = new Date(notification);
    let dateDiff = DateUtils.checkDateDifference(passedDate, today);
    if (dateDiff >= 5) {
      return true;
    }
    return false;
  }

  const close = () => {
    // console.log(
    //   "Notification was closed. Either the close button was clicked or duration time elapsed."
    // );
  };

  function openNotification() {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          notification.close(key);
          window.location.href = "/instructions";
        }}
      >
        Show Me!
      </Button>
    );
    notification.open({
      message: "Add Confident!",
      description: "Have you added the Confident App to your home screen?",
      btn,
      key,
      duration: 0,
      onClose: close,
      placement: "bottomRight",
    });
  }

  return (
    <>
      {screenSize[0] <= 1200 && (
        <>
          <Row>
            <Col className="center-items">
              <img
                src={Logo2}
                style={{ cursor: "pointer" }}
                alt={"Confident Asset Management"}
                className="confident-logo-top-menu"
              ></img>
            </Col>
          </Row>
          <Divider />
        </>
      )}
      <Row>
        <Col className="heading heading-font">Latest News</Col>
      </Row>
      {newsComms.length > 0 &&
        newsComms.map((comm, index) => {
          return <NewsFeedItem key={"news-" + index} newsFeed={comm} />;
        })}
      {newsComms.length === 0 && (
        <Row>
          <Col className="paragraph paragraph-font">
            No news to share, please check again later
          </Col>
        </Row>
      )}
    </>
  );
}
