import configDev from "./config-dev";
import configUAT from "./config-uat";
import configProd from "./config-prod";

const config =
  process.env.REACT_APP_ENV === "prod"
    ? configProd
    : process.env.REACT_APP_ENV === "uat"
    ? configUAT
    : configUAT;

export default config;
