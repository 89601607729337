import DefaultState from "./DefaultState";
import { actionModes } from "./Constants";

export default function Reducer(state = DefaultState, action) {
  if (!action.payload) {
    return state;
  }

  if (!action.mode) {
    console.error("Bad things have happened");
  }

  if (action.mode === actionModes.APPEND_TO_ARRAY) {
    return {
      ...state,
      [action.type]: [...state[action.type], ...action.payload],
    };
  }

  if (action.mode === actionModes.REPLACE) {
    return {
      ...state,
      [action.type]: action.payload,
    };
  }
  if (action.mode === actionModes.REMOVE_FROM_ARRAY) {
    return {
      ...state,
      [action.type]: [
        ...state[action.type].filter((e) => e !== action.payload),
      ],
    };
  }

  return state;
}
