import React from "react";
import "./Error.css";
import Error500Img from "./image/500_Confident.svg";

export default function Error500(error) {
  return (
    <div className="error">
      <img className="error-img" src={Error500Img} alt="" />
      <div className="error-msg">
        <p className="error-msg1 error-msg-font">Oops!</p>
        <p className="error-msg2 error-msg-font">
          Looks like something went wrong!
        </p>
      </div>
    </div>
  );
}
