import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Input, Button } from "antd";
import AuthUtil from "../../utils/AuthUtil";
import { UserOutlined } from "@ant-design/icons";

import "./login.css";
import General from "../../utils/General";

export default function ResetPassword({
  cognitoUser,
  checkTermsAndConditions,
  changeLoginState,
}) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState({
    policy: false,
    confirm: false,
  });

  function resetPassword() {
    if (password !== passwordConfirm) {
      setPasswordError({
        policy: passwordError.policy,
        confirm: true,
      });
    } else {
      General.showLoader();
      AuthUtil.resetPassword(cognitoUser, password, passwordConfirm)
        .then(async (result) => {
          checkTermsAndConditions();
        })
        .catch((error) => {
          setPasswordError({
            policy: true,
            confirm: false,
          });
        })
        .finally(() => General.closeLoader());
    }
  }

  function changePassword(e) {
    setPassword(e.target.value);
    setPasswordError({
      policy: false,
      confirm: false,
    });
  }

  function changeConfirmPassword(e) {
    setPasswordConfirm(e.target.value);
    setPasswordError({
      policy: false,
      confirm: false,
    });
  }

  function goToLogin() {
    changeLoginState("Login");
  }

  return (
    <div>
      <Row>
        <Col className="heading heading-font">Reset Password</Col>
      </Row>
      <Row className="add-small-top-margin">
        <Col>
          <Input.Password placeholder="Password" onChange={changePassword} />
        </Col>
      </Row>
      <Row className="add-small-top-margin">
        <Col>
          <Input.Password
            placeholder="Confirm Password"
            onChange={changeConfirmPassword}
          />
        </Col>
      </Row>
      <Row className="add-small-top-margin center-items">
        <Col>
          <Button onClick={resetPassword} className="login-button">
            Reset Password
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="info-link" onClick={goToLogin}>
          Remember your password? Login!
        </Col>
      </Row>
      {passwordError.confirm && (
        <Row>
          <Col className="error-message">
            The passwords do not match, please re-enter your password.
          </Col>
        </Row>
      )}
      {passwordError.policy && (
        <Row>
          <Col className="error-message">
            Please ensure your password matches the following requirements:
            <li>
              <ul>Minimum 8 characters</ul>
              <ul>Contains numbers</ul>
              <ul>Contains symbols</ul>
              <ul>Contains upper and lowercase letters</ul>
            </li>
          </Col>
        </Row>
      )}
    </div>
  );
}
