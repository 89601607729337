import Amplify, { Auth } from "aws-amplify";
import config from "../config/config";
import AWS from "aws-sdk";

AWS.config.region = config.cognito.REGION;
// AWS.config.secretAccessKey = '';
// AWS.config.accessKeyId = '';
// AWS.config.sessionToken = '';

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.CLIENT_ID,
  },
});
AWS.config.correctClockSkew = true;
let username = "";
let confrimationCode = "";
let isPhoneNumber = false;

export default {
  logout: async function () {
    try {
      await Auth.signOut({ global: true });
      window.location.href = "/login";
    } catch (ex) {
      window.location.href = "/login";
    }
  },

  getIsLoggedIn: function () {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          return resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  },

  login: function (email, password) {
    return Auth.signIn(email.trim(), password.trim())
      .then((user) => {
        return user;
      })
      .catch((error) => {
        console.error("AUTHUTILS: ", error);
        return Promise.reject(error);
      });
  },

  getUserName: async function () {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user);
    return user.signInUserSession.idToken.payload["cognito:username"];
  },

  getUserEmail: async function () {
    const user = await Auth.currentAuthenticatedUser();
    // console.log(user);
    return user.signInUserSession.idToken.payload["email"];
  },

  resetPassword: async function (cognitoUser, newPassword) {
    const user = await Auth.completeNewPassword(cognitoUser, newPassword);
    // console.log(user);
    return user;
  },

  forgotpasswordrequestsend: function (email) {
    let AWSCognito = new AWS.CognitoIdentityServiceProvider();
    let params = {
      Username: email,
      ClientId: config.cognito.CLIENT_ID,
    };
    return AWSCognito.forgotPassword(params)
      .promise()
      .then((user) => {
        return user;
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });

    // return Auth.forgotPassword(email.trim())
    //   .then((user) => {
    //     // console.log(user);
    //     return user;
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     return Promise.reject(error);
    //   });
  },

  forgotpasswordsub: function (email, code, password) {
    return Auth.forgotPasswordSubmit(email.trim(), code.trim(), password.trim())
      .then((user) => {
        return user;
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  },

  getIdToken: async function () {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken;
  },

  getAccesToken: async function () {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.accessToken;
  },

  getUserGroups: async function () {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.accessToken.payload["cognito:groups"];
  },
};
