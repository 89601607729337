import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Api from "../../api/Api";
import PortfolioSelectionComponent from "./PortfolioSelectionComponent";
import { Card } from "antd";
import GeneralUtils from "../../utils/General";
import General from "../../utils/General";

export default function GeographyAndSector() {
  const [sectorData, setSectorData] = useState([]);
  const [geographyData, setGeographyData] = useState([]);
  const [currency, setCurrency] = useState("MUR");
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const [geographyTotal, setGeographyTotal] = useState(0);
  const [sectorTotal, setSectorTotal] = useState(0);
  const [offDropdown, setOffDropdown] = useState("");

  useEffect(() => {
    if (selectedPortfolios.length > 0) {
      General.showLoader();
      Api.getPortfolioAggregatesByGeography(
        currency,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      )
        .then((results) => {
          setGeographyData(results);
          let total = results.reduce((prev, next) => {
            return (prev = prev + next.total);
          }, 0);
          setGeographyTotal(total);
        })
        .finally(() => General.closeLoader());
    }
  }, [offDropdown, currency]);

  useEffect(() => {
    if (selectedPortfolios.length > 0) {
      Api.getPortfolioAggregatesBySector(
        currency,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      ).then((results) => {
        setSectorData(results);
        let total = results.reduce((prev, next) => {
          return (prev = prev + next.total);
        }, 0);
        setSectorTotal(total);
      });
    }
  }, [offDropdown, currency]);

  function changeOffDropDown() {
    setOffDropdown(new Date());
  }

  return (
    <>
      <Row>
        <Col className="heading heading-font">
          Geography and Sector Summary:
        </Col>
      </Row>
      <PortfolioSelectionComponent
        setCurrency={setCurrency}
        currency={currency}
        selectedPortfolios={selectedPortfolios}
        setSelectedPortfolios={setSelectedPortfolios}
        offDropdown={changeOffDropDown}
      />
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col>
          <Card
            title={"Geography Summary"}
            bordered={false}
            style={{ width: "100%", border: "2px solid grey" }}
            headStyle={GeneralUtils.getCardTitleStyle()}
          >
            {geographyData.map((geography, index) => {
              return (
                <Card
                  title={`${
                    geography.name
                  } (${geography.currency?.toUpperCase()})`}
                  type="inner"
                  key={"geography-summary-" + index}
                >
                  <Row>
                    <Col xs={8}>
                      {(
                        Math.round(geography.total * 100) / 100
                      ).toLocaleString()}
                    </Col>
                    <Col xs={4} className="right-align-text">
                      {(
                        Math.round(
                          (geography.total / geographyTotal) * 100 * 100
                        ) / 100
                      ).toLocaleString()}{" "}
                      %
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <Card title={`Total (${currency.toUpperCase()})`} type="inner">
              <Row>
                <Col xs={8}>
                  {(Math.round(geographyTotal * 100) / 100).toLocaleString()}
                </Col>
                <Col xs={4} className="right-align-text">
                  100%
                </Col>
              </Row>
            </Card>
          </Card>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col>
          <Card
            title={"Sector Summary"}
            bordered={false}
            style={{ width: "100%", border: "2px solid grey" }}
            headStyle={GeneralUtils.getCardTitleStyle()}
          >
            {sectorData.map((sector, index) => {
              return (
                <Card
                  title={`${sector.name} (${sector.currency?.toUpperCase()})`}
                  type="inner"
                  key={"sector-summary-" + index}
                >
                  <Row>
                    <Col xs={8}>
                      {(Math.round(sector.total * 100) / 100).toLocaleString()}
                    </Col>
                    <Col xs={4} className="right-align-text">
                      {(
                        Math.round((sector.total / sectorTotal) * 100 * 100) /
                        100
                      ).toLocaleString()}{" "}
                      %
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <Card title={`Total (${currency.toUpperCase()})`} type="inner">
              <Row>
                <Col xs={8}>
                  {(Math.round(sectorTotal * 100) / 100).toLocaleString()}
                </Col>
                <Col xs={4} className="right-align-text">
                  100%
                </Col>
              </Row>
            </Card>
          </Card>
        </Col>
      </Row>
    </>
  );
}
