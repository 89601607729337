import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";

import { Input, Button } from "antd";
import AuthUtil from "../../utils/AuthUtil";
import { UserOutlined } from "@ant-design/icons";

import "./login.css";
import General from "../../utils/General";

export default function ForgotPassword({ changeLoginState }) {
  const [username, setUsername] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [passwordError, setPasswordError] = useState({
    policy: false,
    confirm: false,
  });
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  function login() {
    General.showLoader();
    AuthUtil.forgotpasswordrequestsend(username)
      .then(async (result) => {
        setLoginSuccess(true);
        setLoginError(false);
      })
      .catch((error) => {
        console.log(error);
        setLoginError(true);
      })
      .finally(() => General.closeLoader());
  }

  function resetPassword() {
    if (password !== passwordConfirm) {
      setPasswordError({
        policy: passwordError.policy,
        confirm: true,
      });
    } else {
      General.showLoader();
      AuthUtil.forgotpasswordsub(username, verificationCode, password)
        .then(async (result) => {
          setPasswordSuccess(true);
        })
        .catch((error) => {
          setPasswordError({
            policy: true,
            confirm: false,
          });
        })
        .finally(() => General.closeLoader());
    }
  }

  function changeUsername(e) {
    setUsername(e.target.value);
    setLoginError(false);
  }

  function changePassword(e) {
    setPassword(e.target.value);
    setPasswordError({
      policy: false,
      confirm: false,
    });
  }

  function changeConfirmPassword(e) {
    setPasswordConfirm(e.target.value);
    setPasswordError({
      policy: false,
      confirm: false,
    });
  }

  function changeVerificationCode(e) {
    setVerificationCode(e.target.value);
  }

  function goToLogin() {
    changeLoginState("Login");
  }

  return (
    <div>
      <Row>
        <Col className="heading heading-font">Forgot Password</Col>
      </Row>
      <Row>
        <Col>
          <Input
            placeholder="Email"
            suffix={<UserOutlined className="site-form-item-icon" />}
            onChange={changeUsername}
          />
        </Col>
      </Row>
      <Row className="add-small-top-margin center-items">
        <Col>
          <Button onClick={login} className="login-button">
            Forgot Password
          </Button>
        </Col>
      </Row>
      <Row>
        <Col className="info-link" onClick={goToLogin}>
          Remember your password? Login!
        </Col>
      </Row>
      {loginError && (
        <Row>
          <Col className="error-message">
            Please make sure your email address is correct.
          </Col>
        </Row>
      )}
      {loginSuccess && (
        <>
          <Row>
            <Col>
              Please check your email for instructions on how to reset your
              password.
            </Col>
          </Row>
          <Row className="add-small-top-margin">
            <Col>
              <Input
                placeholder="Verification Code"
                onChange={changeVerificationCode}
              />
            </Col>
          </Row>
          <Row className="add-small-top-margin">
            <Col>
              <Input.Password
                placeholder="Password"
                onChange={changePassword}
              />
            </Col>
          </Row>
          <Row className="add-small-top-margin">
            <Col>
              <Input.Password
                placeholder="Confirm Password"
                onChange={changeConfirmPassword}
              />
            </Col>
          </Row>
          <Row className="add-small-top-margin center-items">
            <Col>
              <Button onClick={resetPassword} className="login-button">
                Reset Password
              </Button>
            </Col>
          </Row>

          {passwordSuccess && (
            <Row>
              <Col>
                Your password has been reset! Please click{" "}
                <span className="info-link" onClick={goToLogin}>
                  here
                </span>{" "}
                to login
              </Col>
            </Row>
          )}
          {passwordError.confirm && (
            <Row>
              <Col className="error-message">
                The passwords do not match, please re-enter your password.
              </Col>
            </Row>
          )}
          {passwordError.policy && (
            <Row>
              <Col className="error-message">
                Please ensure your password matches the following requirements:
                <li>
                  <ul>Minimum 8 characters</ul>
                  <ul>Contains numbers</ul>
                  <ul>Contains symbols</ul>
                  <ul>Contains upper and lowercase letters</ul>
                </li>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
}
