import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Api from "../../api/Api";
import PortfolioSelectionComponent from "./PortfolioSelectionComponent";
import { Card } from "antd";
import GeneralUtils from "../../utils/General";
import ImageUtils from "../../utils/ImageUtils";
import General from "../../utils/General";
import { useHistory } from "react-router-dom";

export default function PortfolioSummary() {
  const [data, setData] = useState({
    mauritius: {
      total: 0,
      percentage: 0,
    },
    international: {
      total: 0,
      percentage: 0,
    },
  });
  const [assetClassData, setAssetClassData] = useState([]);
  const [currency, setCurrency] = useState("MUR");
  const [selectedPortfolios, setSelectedPortfolios] = useState([]);
  const [assetClassTotals, setAssetClassTotals] = useState(0);
  const [offDropdown, setOffDropdown] = useState("");

  let history = useHistory();

  useEffect(() => {
    if (selectedPortfolios.length > 0) {
      General.showLoader();
      Api.getPortfolioAggregatesByLocality(
        null,
        currency,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      )
        .then((results) => {
          let mauritiusTotal = 0,
            internationalTotal = 0;
          results.forEach((portfolio) => {
            if (portfolio.name == "Local")
              // Mauritius
              mauritiusTotal = Math.round(portfolio.total * 100) / 100;
            else internationalTotal = Math.round(portfolio.total * 100) / 100;
          });

          let mauritiusPercentage =
            (mauritiusTotal / (internationalTotal + mauritiusTotal)) * 100;
          let internationalPercentage =
            (internationalTotal / (internationalTotal + mauritiusTotal)) * 100;
          setData({
            mauritius: {
              total: mauritiusTotal,
              percentage: mauritiusPercentage,
            },
            international: {
              total: internationalTotal,
              percentage: internationalPercentage,
            },
          });
        })
        .finally(() => General.closeLoader());
    }
  }, [offDropdown, currency]);

  useEffect(() => {
    if (selectedPortfolios.length > 0) {
      Api.getPortfolioAggregatesByAssetClasses(
        null,
        currency,
        null,
        GeneralUtils.convertPortfolios(selectedPortfolios)
      ).then((results) => {
        setAssetClassData(results);
        let assetClassTotal = results.reduce((prev, next) => {
          return (prev = prev + next.total);
        }, 0);
        setAssetClassTotals(assetClassTotal);
      });
    }
  }, [offDropdown, currency]);

  useEffect(() => {
    return () => {
      setData({
        mauritius: {
          total: 0,
          percentage: 0,
        },
        international: {
          total: 0,
          percentage: 0,
        },
      });
      setCurrency("MUR");
    };
  }, []);

  function redirect(name) {
    let newLink = "/";
    newLink = newLink + name;
    history.push(newLink);
    history.push("/");
    history.goBack();
  }

  function changeOffDropDown() {
    setOffDropdown(new Date());
  }

  return (
    <>
      <Row>
        <Col className="heading heading-font">Portfolio Summary</Col>
      </Row>
      <PortfolioSelectionComponent
        setCurrency={setCurrency}
        currency={currency}
        selectedPortfolios={selectedPortfolios}
        setSelectedPortfolios={setSelectedPortfolios}
        offDropdown={changeOffDropDown}
      />
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col>
          <Card
            title={"Regional Investment Summary"}
            bordered={false}
            style={{ width: "100%" }}
            headStyle={GeneralUtils.getCardTitleStyle()}
          >
            <Card
              title={`Mauritius (${currency})`}
              type="inner"
              extra={ImageUtils.getMauritiusImage()}
              onClick={() => redirect("portfoliosummary/mauritius")}
            >
              <Row>
                <Col xs={8}>{data.mauritius.total.toLocaleString()}</Col>
                <Col xs={4} className="right-align-text">
                  {data.mauritius.percentage.toLocaleString()}%
                </Col>
              </Row>
            </Card>
            <Card
              title={`International (${currency})`}
              type="inner"
              extra={ImageUtils.getInternationalImage()}
              onClick={() => redirect("portfoliosummary/international")}
            >
              <Row>
                <Col xs={8}>{data.international.total.toLocaleString()}</Col>
                <Col xs={4} className="right-align-text">
                  {data.international.percentage.toLocaleString()}%
                </Col>
              </Row>
            </Card>

            <Card
              title={`Total (${currency})`}
              type="inner"
              extra={ImageUtils.getTotalImage()}
            >
              <Row>
                <Col xs={8}>
                  {(
                    data.mauritius.total + data.international.total
                  ).toLocaleString()}
                </Col>
                <Col xs={4} className="right-align-text">
                  {(
                    data.mauritius.percentage + data.international.percentage
                  ).toLocaleString()}{" "}
                  %
                </Col>
              </Row>
            </Card>
          </Card>
        </Col>
      </Row>
      <Row className="paragraph-font align-items-vertical-center add-top-margin">
        <Col>
          <Card
            title={"Asset Class Investment Summary"}
            bordered={false}
            style={{ width: "100%" }}
            headStyle={GeneralUtils.getCardTitleStyle()}
          >
            {assetClassData.map((assetClass, index) => {
              return (
                <Card
                  title={`${assetClass.name} (${assetClass.currency})`}
                  type="inner"
                  extra={ImageUtils.getAssetClassImage(assetClass.name)}
                  key={"portfolio-summary-" + index}
                >
                  <Row>
                    <Col xs={8}>
                      {(
                        Math.round(assetClass.total * 100) / 100
                      ).toLocaleString()}
                    </Col>
                    <Col xs={4} className="right-align-text">
                      {(
                        Math.round(
                          (assetClass.total / assetClassTotals) * 100 * 100
                        ) / 100
                      ).toLocaleString()}{" "}
                      %
                    </Col>
                  </Row>
                </Card>
              );
            })}
            <Card
              title={`Total (${currency})`}
              type="inner"
              extra={ImageUtils.getTotalImage()}
            >
              <Row>
                <Col xs={8}>
                  {(Math.round(assetClassTotals * 100) / 100).toLocaleString()}
                </Col>
                <Col xs={4} className="right-align-text">
                  100%
                </Col>
              </Row>
            </Card>
          </Card>
        </Col>
      </Row>
    </>
  );
}
