import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Api from "../../api/Api";
import { Select, Button } from "antd";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import General from "../../utils/General";

import "./Portfolio.css";

const { Option, OptGroup } = Select;
export default function PortfolioSelectionComponent({
  currency,
  setCurrency,
  selectedPortfolios,
  setSelectedPortfolios,
  offDropdown,
}) {
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    return () => {
      setAllPortfolios([]);
      setCurrency("MUR");
      setSelectedPortfolios([]);
      setClients([]);
      offDropdown("");
    };
  }, []);

  useEffect(() => {
    Api.getUserPortfolios().then((results) => {
      let portfolios = [];
      let sources = [];
      if (results && results.length) {
        setClients(results);
        results.forEach((client) => {
          if (client.portfolios && client.portfolios.length) {
            client.portfolios.forEach((portfolio) => {
              if (portfolio.sources === null || portfolio.sources === "") {
                portfolio["sources"] = [{ name: "Unknown" }];
              }
              if (portfolio.sources && portfolio.sources.length > 0) {
                portfolio.sources.forEach((source) => {
                  if (sources.indexOf(source.name) === -1) {
                    sources.push(source.name);
                  }
                });
              }
              portfolios.push({
                id: portfolio.id,
                name: portfolio.name,
                sources: portfolio.sources,
                label: portfolio.name,
                value: portfolio.id,
              });
            });
          }
        });
      }
      sources = sources.sort(function (a, b) {
        return a.localeCompare(b);
      });
      let groupedOptions = sources.map((source) => {
        return {
          label: source,
          options: portfolios.filter(
            (element) => element.sources[0].name === source
          ),
        };
      });
      setSelectedPortfolios(portfolios);
      setAllPortfolios(groupedOptions);
      offDropdown();
    });
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(value.filter((o) => !o.options));
    } else {
      this.setState(value.filter((o) => !o.options));
    }
  }

  function openReport() {
    Api.getClientReportByType(clients[0].clientNumber, "PortfolioSummary").then(
      (report) => {
        General.getIosWarning();
        if (report.length > 0) {
          report = report.sort(function (a, b) {
            return (
              new Date(b.reportDate).getTime() -
              new Date(a.reportDate).getTime()
            );
          });
          return Api.getReportUrl(report[0].reportKey).then((res) => {
            window.open(res["tempUrl"], "_blank");
          });
        }
      }
    );
  }

  function generateReport(
    reportType,
    currency,
    userId,
    clientNumber,
    portfolioIds,
    fromDate,
    toDate,
    displayLocalPerformance,
    displayInternationalPerformance,
    displayMonthlyPerformance,
    displayLineItems,
    isUnreconciled,
    inceptionPerf
  ) {
    Api.generatePdf(
      reportType,
      currency,
      userId,
      clientNumber,
      portfolioIds,
      fromDate,
      toDate,
      displayLocalPerformance,
      displayInternationalPerformance,
      displayMonthlyPerformance,
      displayLineItems,
      isUnreconciled,
      inceptionPerf
    ).then((res) => {
      General.getIosWarning();
      window.open(res["reportTempURL"], "_blank");
    });
  }

  async function changeDropdown() {
    //NEED THIS TIMEOUT - OTHERWISE THE DROPDOWN ON PORTFOLIO SELECTION COMPONENT DOES NOT CLOSE
    //CHANGE STATE CONFUSES THE DROPDOWN
    //STUPID COMPONENT
    //DO NOT REMOVE

    await new Promise((r) => setTimeout(r, 700));
    offDropdown();
  }

  return (
    <>
      <Row>
        <Col className="paragraph-font">
          <Row className="add-top-margin">
            <Col xs={5}>Display:</Col>
            <Col xs={7}>
              <ReactMultiSelectCheckboxes
                options={allPortfolios}
                placeholderButtonLabel="Portfolios"
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedPortfolios}
                onChange={onChange}
                setState={setSelectedPortfolios}
                onMenuClose={changeDropdown}
              />
            </Col>
          </Row>
          <Row className="add-top-margin">
            <Col xs={5}>Currency:</Col>
            <Col xs={7}>
              <Select
                placeholder="Please select"
                defaultValue={currency}
                onChange={setCurrency}
              >
                <Option value={"MUR"}>{"MUR"}</Option>
                <Option value={"EUR"}>{"EUR"}</Option>
                <Option value={"GBP"}>{"GBP"}</Option>
                <Option value={"USD"}>{"USD"}</Option>
                <Option value={"CHF"}>{"CHF"}</Option>
              </Select>
            </Col>
          </Row>
          <Row className="add-top-margin">
            <Col xs={5}>View your report:</Col>
            <Col xs={7}>
              <Button shape="circle" onClick={openReport}>
                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
