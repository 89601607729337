import React from "react";
import "./Error.css";
import Error404Img from "./image/404_Confident.svg";

export default function Error404(error) {
  return (
    <div className="error">
      <img className="error-img" src={Error404Img} alt="" />
      <div className="error-msg">
        <p className="error-msg1 error-msg-font">Oops!</p>
        <p className="error-msg2 error-msg-font">
          Looks like something went wrong!
        </p>
      </div>
    </div>
  );
}
