import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import Mauritius from "../images/mauritius_brown.png";
import PublicIcon from "@mui/icons-material/Public";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Equity from "../images/Equity.svg";
import FixedIncome from "../images/FixedIncome.svg";
import AlternativeInv from "../images/AlternativeInv.svg";
import Commodities from "../images/Commodities.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default {
  getNotificationCss: function (notificationType) {
    switch (notificationType) {
      case "Urgent":
        return "urgent-notification";
      case "Warning":
        return "warning-notification";
      default:
        return "general-notification";
    }
  },
  getNotificationImage: function (notificationType) {
    return (
      <ErrorRoundedIcon className={this.getNotificationCss(notificationType)} />
    );
  },

  getMauritiusImage: function () {
    return <img src={Mauritius} className="mauritius-small-size"></img>;
  },

  getTotalImage: function () {
    return <CheckCircleIcon className="confident-blue-color" />;
  },

  getInternationalImage: function () {
    return <PublicIcon className="confident-blue-color" />;
  },

  getAssetClassImage: function (assetClass) {
    switch (assetClass) {
      case "Commodities":
        return <img src={Commodities} className="confident-blue-color"></img>;
      case "Equity":
        return <img src={Equity} className="confident-blue-color"></img>;
      case "Alternative Inv":
        return (
          <img src={AlternativeInv} className="confident-blue-color"></img>
        );
      case "Fixed Income":
        return <img src={FixedIncome} className="confident-blue-color"></img>;
      case "Cash":
        return <LocalAtmIcon className="confident-blue-color" />;
    }
  },
  getMoreDetails: function () {
    return <ChevronRightIcon />;
  },
  getCloseDetails: function () {
    return <ChevronLeftIcon />;
  },
};
